import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Layout } from "../components/Layout";
import { Link } from "gatsby";
import Button from "../components/buttons/button";
import ProductBanner from "../components/product_banners/product_banners";
import { SalWrapper } from "react-sal";
import Banner from "../imgs/bg_comoempezar.jpg";
import IframeVIdeo from "../components/iframe_video/iframe_video";
import Image from "../components/image/image";
import QRCode from "../imgs/QRDescarga.png";
import PlayStore from "../imgs/PlayStore.png";
import AppStore from "../imgs/AppStore.png";
import ImagePasoUno from "../imgs/1.jpg";
import ImagePasoDos from "../imgs/2.jpg";
import ImagePasoTres from "../imgs/3.jpg";
import Faq from "../components/faq/faq";
import { apiRequest } from "../apiRquest/apiRequest";
/*import "../pages/pages.css";*/
import "./como-empezar.css";
import { Helmet } from "react-helmet";
import "./descarga-app.css";
let text = `<span class='highlightsbold'> Tu plataforma de inversión,</span> <br/> al alcance de tu mano.`;
const pageTitle =
  "Balanz - La Mejor App para Invertir Online en Argentina ¡Descárgala Gratis!";
const pageDesc =
  "Balanz, tu opción ideal para invertir online en Argentina. Descarga la app y comienza a invertir tu dinero.";
const DescargaApp = () => {
    const [faq, setFaq] = useState({
        list: []
    });
    useEffect(() => {
        apiRequest
            .get("/v1/faq/21")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })
                })
                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
  const page = (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
                        {
                            "@context": "https://schema.org",
                            "@type": "VideoObject",
                            "name": "Balanz en tu celular",
                            "description": "Balanz - La Mejor App para Invertir
                            Online en Argentina ¡Descárgala Gratis!",
                        }
                    `}
        </script>
      </Helmet>
      <ProductBanner
        bannerContainerClassName="bannerCedear"
        bannerTrasparecy="bannerMask"
        productTitle="Balanz en tu celular"
        bannerImage={Banner}
        bannerImageClassName="cedearsBannerImage"
        textContainerClassName="textContainer"
        bannerText={text}
        bannerTextClassName="highlightsbold white"
        bannerImageMobile={Banner}
        mobile={false}
      />
      <SalWrapper>
        <section className="fullwidth apertura-online">
          <Container>
            <Row className="">
              <h2
                className="mt-2"
                data-sal="fade"
                data-sal-delay="0"
                data-sal-duration="200"
              >
                <span className="smaller-title">
                  Descargá la App de Balanz y operá desde donde estés
                </span>
              </h2>

              <Col xs={12}>
                <p className="base-text">
                  {" "}
                  Con nuestra aplicación de inversiones, podes hacer crecer tu
                  dinero y potenciar tu destino financiero. <br/>
                  <b>Descargala ahora y
                  descubrí la mejor forma de invertir.</b>
                </p>
              </Col>
              <div className="container-img">
                <div className="group-a">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.mobile.balanz.droid&utm_source=Website&utm_medium=Banner&utm_campaign=Android"
                    target="_blank"
                  >
                    <img
                      src={PlayStore}
                      alt="Google Play"
                      className="google-play mb-3"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/balanz/id1318206099?utm_source=Website&utm_medium=Banner&utm_campaign=IOS"
                    target="_blank"
                  >
                    <img src={AppStore} alt="App Store" className="app-store" />
                  </a>
                </div>
                <div className="group-b ml-3">
                  <img className="qr-code " src={QRCode} alt="QR Code" />
                </div>
              </div>
            </Row>
            <Row className="py-2">
              <Col xs={12}>
                <h2>
                  <span className="smaller-title">
                    ¿Qué podés hacer desde la APP?
                  </span>
                </h2>
                <ul className="triangle-list list-text">
                  <li data-sal="slide-up">
                    <Link to="/abrir-cuenta">
                      <strong style={{ color: "#00A3E8" }}>
                        Abrir tu cuenta{" "}
                      </strong>
                    </Link>{" "}
                    gratis en 5 minutos e invertir online.
                  </li>

                  <li data-sal="slide-up">
                    Comprar
                    <Link to="/inversiones/dolar-mep/">
                      <strong style={{ color: "#00A3E8" }}> Dólar MEP</strong>
                    </Link>{" "}
                  </li>

                  <li data-sal="slide-up">
                    Invertir en
                    <Link to="/inversiones/acciones">
                      <strong style={{ color: "#00A3E8" }}> Acciones</strong>,
                    </Link>
                    <Link to="/inversiones/cedears/">
                      <strong style={{ color: "#00A3E8" }}> CEDEARs</strong>,
                    </Link>
                    <strong style={{ color: "#00A3E8" }}>
                      {" "}
                      <Link to="/inversiones/bonos/"> Bonos</Link>
                    </strong>
                    , en pesos y en dólares.
                  </li>

                  <li data-sal="slide-up">
                    Suscribir a
                    <Link to="/inversiones/fondos/">
                      <strong style={{ color: "#00A3E8" }}>
                        {" "}
                        Fondos Comunes de Inversión
                      </strong>
                    </Link>{" "}
                  </li>

                  <li data-sal="slide-up">
                    Comprar en un click nuestros
                    <Link to="/inversiones/cedears/packs-de-cedears/">
                      <strong style={{ color: "#00A3E8" }}>
                        {" "}
                        Packs de CEDEARs{" "}
                      </strong>
                    </Link>{" "}
                    creados por
                    <Link to="/investment-ideas/">
                      <strong style={{ color: "#00A3E8" }}>
                        {" "}
                        Investment Ideas
                      </strong>
                    </Link>
                    , nuestro equipo de expertos.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="fullwidth apertura-online">
          <Container>

          </Container>
        </section> */}
        <section
          className="fullwidthbg inversor"
          data-sal="fade"
          data-sal-delay="0"
          data-sal-duration="200"
        >
          <Container className="d-flex align-items-stretch">
            <Row className="d-flex justify-content-start align-items-center flex-grow-1 flex-shrink-1">
              <Col xs={12} md={9}>
                <div className="section-content">
                  <h2
                    className="titulo"
                    data-sal="slide-up"
                    data-sal-delay="200"
                    data-sal-duration="200"
                  >
                    <span className="tit-60">
                      Manejá tus inversiones de{" "}
                      <span className="highlightsbold">
                        forma simple, segura y online
                      </span>
                    </span>
                  </h2>
                  <div
                    className=" d-md-block cta mt-3 mb-2"
                    data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-duration="200"
                  >
                    <Button
                      id="manejar-inversiones-button"
                      variant="secondary"
                      text="Abrir Cuenta"
                      onClick={() => {
                        window.open(
                          "https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1381",
                          "_blank"
                        );
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="fullwidthbg section mb-5"
          data-sal="fade"
          data-sal-delay="0"
          data-sal-duration="200"
        >
          <div className="container">
            <h2 className="title-pasos">
              <span className="smaller-title">Ahora invertir es más fácil</span>
            </h2>

            <p className="base-text">
              Recibí notificaciones de tus instrumentos favoritos y seguí el
              mercado en tiempo real desde tu celular.
            </p>
            <div className="row container-pasos">
              <div className="col-md-4">
                <div className="image-container text-center">
                  <div className="image-text text-center mb-5">
                    <p className="base-text">
                    <b>Consultá tus movimientos en todo momento</b>
                    </p>
                  </div>
                  <img
                    src={ImagePasoUno}
                    alt="Texto alternativo de la imagen 1"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="image-container text-center">
                  <div className="image-text text-center mb-5">
                    {" "}
                    <p className="base-text">
                      <b>Accedé a todas las cotizaciones, todo el tiempo, en un
                      solo lugar</b>
                    </p>
                  </div>
                  <img
                    src={ImagePasoDos}
                    alt="Texto alternativo de la imagen 2"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="image-container text-center">
                  <div className="image-text text-center mb-5">
                    {" "}
                    <p className="base-text">
                    <b>Administrá la composición de tus inversiones de manera
                      fácil y segura</b>
                    </p>
                  </div>
                  <img
                    src={ImagePasoTres}
                    alt="Texto alternativo de la imagen 3"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="fullwidthbg appdownload2"
          data-sal="fade"
          data-sal-delay="0"
          data-sal-duration="300"
        >
          <Container>
            <Row className="d-flex align-items-stretch">
              <Col>
                <div className="section-content">
                  <h3
                    className="titulo primary-color"
                    data-sal="fade"
                    data-sal-delay="200"
                    data-sal-duration="300"
                  >
                    <span className="tit-45">
                      Descargá nuestra App <br className="d-none d-md-inline" />
                      <span className="highlightbold">
                        y empezá a invertir.
                      </span>
                    </span>
                  </h3>
                  <div
                    className="cta"
                    data-sal="fade"
                    data-sal-delay="300"
                    data-sal-duration="300"
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.mobile.balanz.droid&utm_source=Website&utm_medium=Banner&utm_campaign=Android"
                      target="_blank"
                    >
                      <Image
                        className="store-img"
                        src="PlayStore.png"
                        alt="GStore"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/balanz/id1318206099?utm_source=Website&utm_medium=Banner&utm_campaign=IOS"
                      target="_blank"
                    >
                      <Image
                        className="store-img"
                        src="AppStore.png"
                        alt="AStore"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="fullwidth preguntas-frecuentes">
          <Container>
            <Row>
              <Faq faq={faq} customClass={"opciones-faq"} />
            </Row>
          </Container>
        </section>
      </SalWrapper>
    </>
  );

  return (
    <main>
      <Layout
        title={pageTitle}
        description={pageDesc}
        childrem={page}
        category={"descarga-app"}
      ></Layout>
    </main>
  );
};

// Step 3: Export your component
export default DescargaApp;
